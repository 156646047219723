<template>
  <IAmOverlay :loading="loading">
    <component :is="agenciesData === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="agenciesData === undefined"
      >
        <h4 class="alert-heading">
          {{ $t('agency.errFetchAgency') }}
        </h4>
        <div class="alert-body">
          {{ $t('agency.notFundAgencyById_1') }}
          <b-link
            class="alert-link"
            :to="{ name: 'apps-agencies-list'}"
          >
            {{ $t('agency.notFundAgencyById_2') }}
          </b-link>
          {{ $t('agency.notFundAgencyById_3') }}
        </div>
      </b-alert>

      <div
        v-if="agenciesData"
        class="tabs"
      >
        <b-nav pills>
          <b-nav-item
            to="#"
            :active="$route.hash === '#' || $route.hash === ''"
          >
            <div class="d-flex-center">
              <feather-icon
                icon="UserIcon"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('general') }}</span>
            </div>
          </b-nav-item>
          <b-nav-item
            to="#employees"
            :active="$route.hash === '#employees'"
          >
            <div class="d-flex-center">
              <feather-icon
                icon="UsersIcon"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('employees') }}</span>
            </div>
          </b-nav-item>
          <b-nav-item
            to="#manager"
            :active="$route.hash === '#manager'"
          >
            <div class="d-flex-center">
              <feather-icon
                icon="UserIcon"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('agency.manager') }}</span>
            </div>
          </b-nav-item>

          <b-nav-item
            v-if="meData?.type === 'ADM'"
            to="#bank-accounts"
            :active="$route.hash === '#bank-accounts'"
          >
            <div class="d-flex-center">
              <IAmIcon
                icon="dollar"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('bankAccountsInfo') }}</span>
            </div>
          </b-nav-item>
          <b-nav-item
            v-if="!isHideInvoice"
            to="#company"
            :active="$route.hash === '#company'"
          >
            <div class="d-flex-center">
              <IAmIcon
                icon="buildingOutline"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('agency.company.title') }}</span>
            </div>
          </b-nav-item>
          <b-nav-item
            v-if="['ADM','OPE'].includes(meData?.type)"
            to="#web-config"
            :active="$route.hash === '#web-config'"
          >
            <div class="d-flex-center">
              <IAmIcon
                icon="internet"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('agency.webConfig.title') }}</span>
            </div>
          </b-nav-item>
        </b-nav>
        <div class="tab-content">
          <div
            :class="['tab-pane', { 'active': $route.hash === '#' || $route.hash === '' }]"
            class="p-0"
          >
            <AgenciesEditTabAccount
              :agencies-data="agenciesData"
              :loading-prop.sync="loading"
              @refetchAgency="refetchAgency"
            />
          </div>
          <div
            :class="['tab-pane', { 'active': $route.hash === '#employees' }]"
            class="p-0"
          >
            <AgenciesEditTabEmployee
              :agencies-data="agenciesData"
              :loading-prop.sync="loading"
              @refetchAgency="refetchAgency"
            />
          </div>
          <div
            :class="['tab-pane', { 'active': $route.hash === '#manager' }]"
            class="p-0"
          >
            <AgenciesManagerTab :manager-employees="agenciesData?.managers.map(item => item.employee)" />
          </div>
          <div
            v-if="meData?.type === 'ADM'"
            :class="['tab-pane', { 'active': $route.hash === '#bank-accounts' }]"
            class="p-0"
          >
            <AccountSettingBankAccountsList :agency-data="meData?.agency" />
          </div>
          <div
            v-if="!isHideInvoice"
            :class="['tab-pane', { 'active': $route.hash === '#company' }]"
            class="p-0"
          >
            <AgenciesEditCompany
              :agency-company="agenciesData?.company"
              :agency-id="agenciesData?.id"
              @refetchAgency="refetchAgency"
            />
          </div>
          <div
            v-if="['ADM','OPE'].includes(meData?.type)"
            :class="['tab-pane', { 'active': $route.hash === '#web-config' }]"
            class="p-0"
          >
            <AgenciesEditWebConfig
              :agency-web-config="agenciesData?.agencyConfig"
              :agency-id="agenciesData?.id"
              @refetchAgency="() => {
                refetchAgency()
                refetchAgencyConfig()
              }"
            />
          </div>
        </div>
      </div>
    </component>
  </IAmOverlay>
</template>

<script>
import {
  BCard, BAlert, BLink, BNav, BNavItem,
} from 'bootstrap-vue'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'

import store from '@/store'
import { env } from '@/libs/env'

import agenciesStoreModule from '@agencies/agenciesStoreModule'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BNav,
    BNavItem,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    AgenciesEditTabAccount: () => import('./AgenciesEditTabAccount.vue'),
    AgenciesManagerTab: () => import('@agencies/agencies-edit/AgenciesManagerTab.vue'),
    AgenciesEditTabEmployee: () => import('./AgenciesEditTabEmployee.vue'),
    AccountSettingBankAccountsList: () => import('./AgencySettingBankAccountsList.vue'),
    AgenciesEditCompany: () => import('@agencies/agencies-edit/AgenciesEditCompany.vue'),
    AgenciesEditWebConfig: () => import('@agencies/agencies-edit/AgenciesEditWebConfig.vue'),
  },
  setup() {
    const agenciesData = ref(null)

    const AGENCIES_APP_STORE_MODULE_NAME = 'app-agencies'

    // Register module
    if (!store.hasModule(AGENCIES_APP_STORE_MODULE_NAME)) store.registerModule(AGENCIES_APP_STORE_MODULE_NAME, agenciesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AGENCIES_APP_STORE_MODULE_NAME)) store.unregisterModule(AGENCIES_APP_STORE_MODULE_NAME)
    })
    const loading = ref(false)
    const meData = computed(() => store.getters['userStore/getMeData'])

    async function refetchAgency() {
      loading.value = true
      try {
        const agencyRefetch = await store.dispatch('app-agencies/getAgenciesById', { id: meData.value.agency.id })
        await store.dispatch('userStore/fetchMeData')
        if (agencyRefetch) {
          agenciesData.value = agencyRefetch.data
        }
      } catch (error) {
        if (error.response.status === 404) {
          agenciesData.value = undefined
        }
      } finally {
        loading.value = false
      }
    }

    watch(meData, val => {
      if (val && !agenciesData.value && !loading.value) {
        refetchAgency()
      }
    }, { immediate: true })

    function refetchAgencyConfig() {
      store.dispatch('app/loadConfig', { hostname: window.location.host, clearCache: true })
    }
    return {
      agenciesData,
      loading,
      meData,
      refetchAgency,
      refetchAgencyConfig,
      isHideInvoice: env.isHideInvoice,
    }
  },
}
</script>
